// @font-face {
// 	font-family: 's-core-dream';
// 	src: url('/fonts/scdream1.woff2') format('woff2'),
// 			 url('/fonts/scdream1.woff') format('woff');
// 	font-weight: 100;
// 	font-style: normal;
// }
// @font-face {
// 	font-family: 's-core-dream';
// 	src: url('/fonts/scdream2.woff2') format('woff2'),
// 			 url('/fonts/scdream2.woff') format('woff');
// 	font-weight: 200;
// 	font-style: normal;
// }
// @font-face {
// 	font-family: 's-core-dream';
// 	src: url('/fonts/scdream3.woff2') format('woff2'),
// 			 url('/fonts/scdream3.woff') format('woff');
// 	font-weight: 300;
// 	font-style: normal;
// }
@font-face {
	font-family: 's-core-dream';
	src: url('/fonts/scdream4.woff2') format('woff2'),
			 url('/fonts/scdream4.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
// @font-face {
// 	font-family: 's-core-dream';
// 	src: url('/fonts/scdream5.woff2') format('woff2'),
// 			 url('/fonts/scdream5.woff') format('woff');
// 	font-weight: 500;
// 	font-style: normal;
// }
// @font-face {
// 	font-family: 's-core-dream';
// 	src: url('/fonts/scdream6.woff2') format('woff2'),
// 			 url('/fonts/scdream6.woff') format('woff');
// 	font-weight: 600;
// 	font-style: normal;
// }
@font-face {
	font-family: 's-core-dream';
	src: url('/fonts/scdream7.woff2') format('woff2'),
			 url('/fonts/scdream7.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
// @font-face {
// 	font-family: 's-core-dream';
// 	src: url('/fonts/scdream8.woff2') format('woff2'),
// 			 url('/fonts/scdream8.woff') format('woff');
// 	font-weight: 800;
// 	font-style: normal;
// }
// @font-face {
// 	font-family: 's-core-dream';
// 	src: url('/fonts/scdream9.woff2') format('woff2'),
// 			 url('/fonts/scdream9.woff') format('woff');
// 	font-weight: 900;
// 	font-style: normal;
// }