@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #__next {
	height: 100%;
	width: 100%;
}

main {
	background-color: #f4f4f4;
}

@layer utilities {
	@variants responsive {
		.h-248px {
			height: 248px;
		}
	}
}
