.swiper-button-next, .swiper-button-prev {
  color: white;
}

.swiper-button-next {
  right: 20px;
}

.swiper-button-prev {
  left: 20px;
}

.swiper-pagination-bullet-active {
  background: white;
}
